import React from 'react';
import ReactDOM from 'react-dom';

import { 
  BrowserRouter as Router
} from "react-router-dom";

import './index.css';

import App from './App';

import * as serviceWorker from './serviceWorker';

// Changed from default to add special meaning via the data attribute as opposed to id to seperate concerns
const rootElement = document.querySelector('[data-role-react-root]');

ReactDOM.render(
  <Router>
    <App />
  </Router>, 
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
